<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <push-notification-form :submit-form="submitForm" />
      </validation-observer>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Planlanmış Bildirimler</b-card-title>
      </b-card-header>
      <push-plan-list />
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Hesap Listesi</b-card-title>
      </b-card-header>
      <customer-list />
    </b-card>
  </div>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import { BCard, BCardHeader, BCardTitle } from 'bootstrap-vue'
import PushPlanList from '@/views/Admin/Orders/PushNotifications/PushPlanList.vue'
import PushNotificationForm from '@/views/Admin/Orders/PushNotifications/PushNotificationForm.vue'
import CustomerList from '@/views/Admin/Orders/PushNotifications/CustomerList.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'PushNotifications',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    PushPlanList,
    CustomerList,
    PushNotificationForm,
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['pushNotifications/dataItem']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    submitForm(isNow = false) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.id_orders = this.$route.params.id
          this.dataItem.is_now = isNow
          this.$store.dispatch('pushNotifications/saveData', this.dataItem)
        } else {
          this.showToast({
            title: 'Uyarı',
            icon: 'InfoIcon',
            text: 'Lütfen tüm alanları doldurun.!',
            variant: 'warning',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
