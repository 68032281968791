<template>
  <div>
    <b-form-group
      label="Sipariş Özel Şartlar Şablonu"
      label-for="id_terms"
    >
      <v-select
        id="id_terms"
        v-model="selectedTerms"
        :options="orderTerms"
        label="title"
        placeholder="Seçiniz"
        :reduce="item => item.content"
        @input="setTerms"
      />
    </b-form-group>
    <order-term />
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import OrderTerm from '@/views/Admin/Orders/elements/OrderTerm.vue'

export default {
  name: 'Terms',
  components: {
    vSelect,
    BFormGroup,
    OrderTerm,
  },
  data() {
    return {
      selectedTerms: null,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['orders/dataItem']
    },
    orderTerms() {
      return this.$store.getters['orderTerms/dataList']
    },
  },
  created() {
    this.getOrderTerms()
  },
  methods: {
    setTerms() {
      if (this.selectedTerms) {
        this.dataItem.terms = this.selectedTerms
      }
    },
    getOrderTerms() {
      this.$store.dispatch('orderTerms/getDataList')
    },
  },
}
</script>
