<template>
  <div>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <div class="border p-1 rounded text-center">
            <div class="font-weight-bold text-primary">
              Sipariş No
            </div>
            {{ dataItem.onumber }}
          </div>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <div class="border p-1 rounded text-center">
            <div class="font-weight-bold text-primary">
              Sipariş Tarihi
            </div>
            {{ moment(dataItem.odate).format('DD.MM.YYYY') }}
          </div>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <div class="border p-1 rounded text-center">
            <div class="font-weight-bold text-primary">
              Teslimat Tarihi
            </div>
            {{ dataItem.ddate? moment(dataItem.ddate).format('DD.MM.YYYY') : '-' }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="12"
          md="4"
        >
          <div class="border p-1 rounded text-center">
            <div class="font-weight-bold text-primary">
              Sipariş Durumu
            </div>
            {{ dataItem.order_status }}
          </div>
        </b-col>
        <b-col cols="4">
          <div class="border p-1 rounded text-center">
            <div class="font-weight-bold text-primary">
              Sipariş Oluşturma
            </div>
            {{ dataItem.customer_account? dataItem.customer_account : '-' }}
          </div>
        </b-col>
        <b-col cols="4">
          <div class="border p-1 rounded text-center">
            <div class="font-weight-bold text-primary">
              Satış Temsilcisi
            </div>
            {{ dataItem.username }}
          </div>
        </b-col>
        <b-col cols="12">
          <div class="border p-1 rounded mt-2">
            <div class="font-weight-bold text-primary">
              Müşteri Sipariş Notu
            </div>
            {{ dataItem.notes }}
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BRow, BCol, BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'OfferSummary',
  components: {
    BRow,
    BCol,
    BCardBody,
  },
  computed: {
    dataItem() {
      return this.$store.getters['orders/dataItem']
    },
  },
}
</script>
