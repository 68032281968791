<template>
  <validation-provider
    #default="{ errors }"
    name="Sipariş Durumu"
    rules="required"
  >
    <b-form-group
      label="Sipariş Durumu"
      label-for="id_order_statuses"
    >
      <v-select
        id="id_order_statuses"
        v-model="dataItem.id_order_statuses"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />
    </b-form-group>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'OrderStatus',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['orders/dataItem']
    },
    dataList() {
      return this.$store.getters['orderStatuses/dataList']
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('orderStatuses/getDataList', {
        select: [
          'order_statuses.id AS id',
          'order_statuses.title AS title',
        ],
      })
    },
  },
}
</script>
