<template>
  <div>
    <b-form-checkbox
      v-model="dataItem.autoPush"
      switch
      value="1"
    >
      Uygulama Bildirimi Gönder
    </b-form-checkbox>
    <b-alert
      v-if="dataItem.autoPush"
      class="mt-1"
      show
      variant="info"
    >
      <div class="alert-body">
        <FeatherIcon icon="InfoIcon" />
        Siparişi durumu için otomatik uygulama bildirimi gönderilecektir.
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BFormCheckbox, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'OrderAutoPush',
  components: {
    BFormCheckbox,
    BAlert,
  },
  computed: {
    dataItem() {
      return this.$store.getters['orders/dataItem']
    },
  },
}
</script>
