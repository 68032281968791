<template>
  <b-tabs
    pills
    fill
  >
    <b-tab active>
      <template #title>
        <feather-icon icon="FileTextIcon" />
        <span>Sipariş Bilgileri</span>
      </template>
      <order-card />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon icon="BellIcon" />
        <span>Uygulama Bildirimi</span>
      </template>
      <push-notifications />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon icon="UserIcon" />
        <span>Müşteri Bilgileri</span>
      </template>
      <customer-info />
      <customer-invoice />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon icon="ClockIcon" />
        <span>Müşteri Geçmişi</span>
      </template>
      <customer-history :customer-id="dataItem.id_customers" />
    </b-tab>
  </b-tabs>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
import PushNotifications from '@/views/Admin/Orders/PushNotifications.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import OrderCard from '@/views/Admin/Orders/OrderCard.vue'

export default {
  name: 'OrderView',
  components: {
    CustomerInfo,
    CustomerInvoice,
    OrderCard,
    PushNotifications,
    CustomerHistory,
    BTabs,
    BTab,
  },
  computed: {
    dataItem() {
      return this.$store.getters['orders/dataItem']
    },
  },
  beforeCreate() {
    this.$store.commit('orders/RESET_DATA_ITEM')
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('orders/getDataView', { id_orders: this.$route.params.id })
        .then(response => {
          if (response.id_customers) {
            this.getCustomer(response.id_customers)
          }
        })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/getDataItem', id)
    },
  },
}
</script>
