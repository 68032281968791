<template>
  <div>
    <template v-if="customerAccounts.length">
      <b-table
        responsive="sm"
        :fields="fields"
        :items="customerAccounts"
        striped
      >
        <template #cell(company)="data">
          <div class="font-weight-bold">
            {{ data.item.company }}
          </div>
          <div class="text-primary">
            {{ data.item.customer_account }}
          </div>
          <div class="text-muted font-small-1">
            {{ data.item.uudi }}
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="pushNotificationCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </template>
    <template v-else>
      <b-card-body>
        <b-alert
          show
          variant="danger"
        >
          <div class="alert-body text-center">
            Bildirim gönderimine uygun müşteri hesabı bulunamadı.
          </div>
        </b-alert>
      </b-card-body>
    </template>
  </div>
</template>
<script>
import {
  BPagination, BTable, BCardFooter, BAlert, BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'CustomerList',
  components: {
    BTable,
    BPagination,
    BCardFooter,
    BAlert,
    BCardBody,
  },
  data() {
    return {
      limit: this.$store.state.app.perPage,
      start: 0,
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'company',
          label: 'MÜŞTERİ',
        },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['orders/dataItem']
    },
    customerAccounts() {
      return this.$store.getters['orders/pushNotificationList']
    },
    pushNotificationCount() {
      return this.$store.getters['orders/pushNotificationCount']
    },
  },
  created() {
    this.getPushNotificationList()
  },
  methods: {
    getPushNotificationList() {
      this.$store.dispatch('orders/getPushNotificationList', {
        id_customers: this.dataItem.id_customers,
        limit: this.limit,
        start: this.start,
      })
    },
  },
}
</script>
