<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card no-body>
        <order-summary />
      </b-card>
      <b-card no-body>
        <b-card-header>
          <b-card-title>Ürünler</b-card-title>
        </b-card-header>
        <products />
      </b-card>
      <b-card>
        <change-status :submit-form="submitForm" />
        <terms />
        <order-auto-push />
      </b-card>
      <b-button
        variant="primary"
        @click="submitForm"
      >
        <FeatherIcon icon="SaveIcon" />
        Kaydet
      </b-button>
    </validation-observer>
  </div>
</template>
<script>
import {
  BButton,
  BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import OrderSummary from '@/views/Admin/Orders/View/OrderSummary.vue'
import Products from '@/views/Admin/Orders/View/Products.vue'
import Terms from '@/views/Admin/Orders/View/Terms.vue'
import ChangeStatus from '@/views/Admin/Orders/elements/ChangeStatus.vue'
import OrderAutoPush from '@/views/Admin/Orders/elements/OrderAutoPush.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'OrderView',
  components: {
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    OrderSummary,
    Products,
    Terms,
    ChangeStatus,
    OrderAutoPush,
    ValidationObserver,
  },
  data() {
    return {
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['orders/dataItem']
    },
    saveData() {
      return this.$store.getters['orders/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.submitStatus = false
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('orders/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
